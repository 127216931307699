import { createSelector } from '@reduxjs/toolkit';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../generic';
import { selectPageConfig } from '../pageConfig';
import type { GenericState } from '../../../types';

export interface SiteBaseSettingsAgendaDates {
  dayNumber: number;
  startDate: string;
  startTime: string;
  endTime: string;
  timeZone: string;
}

export interface SiteBaseSettingsSpeakers {
  path: string;
  name: string;
  jobTitle: string;
  company: string;
}

export interface SiteBaseSettingsSponsorsAndExhibitors {
  path: string;
  name: string;
  webSite: string;
}

export interface SiteBaseSettings {
  data: {
    agendaDates: SiteBaseSettingsAgendaDates[];
    speakers: SiteBaseSettingsSpeakers[];
    sponsorsAndExhibitors: SiteBaseSettingsSponsorsAndExhibitors[];
    primaryLocale?: string;
  };
}

export type SiteBaseSettingsParams = void;

export const fetchSiteBaseSettings = createGenericAsyncThunk<
  SiteBaseSettings,
  SiteBaseSettingsParams
>(
  'siteBaseSettings/fetchSiteBaseSettings',
  async (params, { extra, rejectWithValue, getState }) => {
    const { siteId, siteTypePath } = selectPageConfig(getState());

    const response = await extra.ajax.get(
      `/caas/content/api/v1/${siteTypePath}/${siteId}/baseSettings`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: SiteBaseSettings = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const siteBaseSettingsSlice = createGenericSlice({
  name: 'siteBaseSettings',
  initialState: {
    status: 'idle',
    data: {
      data: {
        agendaDates: [],
        speakers: [],
        sponsorsAndExhibitors: [],
      },
    },
  } as GenericState<SiteBaseSettings>,
  reducers: {},
  extraReducers: (builder) => {
    createGenericBuilderCases<SiteBaseSettings, SiteBaseSettingsParams>(
      builder,
      fetchSiteBaseSettings,
    );
  },
});

export const selectSiteBaseSettings = createSelector(
  [(state) => state.siteBaseSettings.data as SiteBaseSettings],
  (data) => data.data,
);

export const { start, fulfilled, rejected } = siteBaseSettingsSlice.actions;

export default siteBaseSettingsSlice.reducer;
