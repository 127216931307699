import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { HTMLtoJSX } from '../../../../../helpers';
import { renderStyledElementStyles } from '../../../helpers';
import { ThemeNames } from '../../../../../constants';

const ParagraphWrapper = styled.div`
  p + p,
  p + ul,
  p + ol,
  ul + p,
  ol + p,
  ul + ol,
  ol + ul {
    margin-top: 1em;
  }

  b,
  strong {
    font-weight: bold;
  }

  i,
  em {
    font-style: italic;
  }

  p,
  ul {
    ${({ theme }) =>
      renderStyledElementStyles(theme, theme.siteBuilderModules.paragraph)}
  }

  a,
  a:hover {
    color: inherit;
  }

  img {
    display: block;
    max-width: 100%;
  }

  ol,
  ul {
    padding: ${({ theme, alignStyle }) => {
      if (alignStyle === 'CENTRE') {
        return '0';
      }

      return theme.globalVariables.isRTL ? '0 40px 0 0' : '0 0 0 40px';
    }};
    list-style-position: ${({ alignStyle }) =>
      alignStyle === 'CENTRE' && 'inside'} !important;

    li {
      margin: ${({ theme }) =>
        [
          ThemeNames.FanExpoHQ,
          ThemeNames.MEFCC,
          ThemeNames.InteriorDesignShow,
        ].includes(theme.name)
          ? '10px 0'
          : '20px 0'};

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  h3 {
    margin: 0 0 40px;
    font-size: 28px;
    font-weight: 300;
    line-height: 1.2;
  }

  h4 {
    margin: 0 0 30px;
    font-size: 25px;
    font-weight: 700;
    line-height: 1.4;
  }

  h5 {
    margin: 0 0 30px;
    font-size: 21px;
    font-weight: 700;
    line-height: 1.4;
  }

  h6 {
    margin: 0 0 30px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4;
  }
`;

export const Paragraph = ({
  content,
  jsx,
  children,
  testId,
  className,
  alignStyle,
}) => (
  <ParagraphWrapper
    data-testid={testId}
    className={className}
    alignStyle={alignStyle}
  >
    {jsx ? children : HTMLtoJSX(content)}
  </ParagraphWrapper>
);

Paragraph.propTypes = {
  content: PropTypes.string,
  testId: PropTypes.string,
  jsx: PropTypes.bool,
  children: PropTypes.node,
};

Paragraph.defaultProps = {
  testId: 'informa-paragraph',
};

const StyledParagraphWrapper = styled.div`
  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles?.elements?.paragraph)}
  ${({ theme, visualStyles }) =>
    renderStyledElementStyles(theme, visualStyles?.elements?.paragraph)}
`;

export const StyledParagraph = ({
  content,
  displayStyles,
  visualStyles,
  testId,
  alignStyle,
}) => (
  <StyledParagraphWrapper
    displayStyles={displayStyles}
    visualStyles={visualStyles}
  >
    <Paragraph alignStyle={alignStyle} content={content} testId={testId} />
  </StyledParagraphWrapper>
);

StyledParagraph.propTypes = {
  content: PropTypes.string,
  testId: PropTypes.string,
};

StyledParagraph.defaultProps = {
  displayStyles: null,
  visualStyles: null,
  alignStyle: 'CENTRE'
};
