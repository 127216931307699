import {VideoSource} from "../../../../../types";

export const sourceSettings = {
  [VideoSource.YOUTUBE]: {
    path: 'https://www.youtube.com/embed/',
    allow:
      'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
  },
  [VideoSource.VIMEO]: {
    path: 'https://player.vimeo.com/video/',
    allow: 'autoplay; fullscreen; picture-in-picture;',
  },
  [VideoSource.WISTIA]: {
    path: 'https://fast.wistia.net/embed/iframe/',
    allow: '',
  },
  [VideoSource.STREAMLY_VIDEO]: {
    path: '',
    allow: '',
  },
};

export const defaultOptions = {
  autoplay: 1,
  controls: 1,
  rel: 0,
};
